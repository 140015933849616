import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useAnalytics } from "shared/providers/AnalyticsContext";
import { PostHogEvents } from "shared/models/enums";

const TrialExpiredModal = ({
  disclosure,
  onUpgrade,
  isOwner,
  trialModalKey,
}) => {
  const [isMonthlySelected, setIsMonthlySelected] = useState(false);
  const { trackEvent } = useAnalytics();

  const onUpgradeClick = (isUpgrading: boolean) => {
    trackEvent(
      isUpgrading
        ? PostHogEvents.USER_UPGRADED_AFTER_TRIAL_EXPIRATION
        : PostHogEvents.USER_REMAINED_ON_FREE_PLAN,
      {}
    );
    onUpgrade(isUpgrading);
  };

  const onModalClose = () => {
    trackEvent(PostHogEvents.USER_REMAINED_ON_FREE_PLAN, {});
    localStorage.setItem(trialModalKey, "true");
    disclosure.onClose();
  };

  return (
    <Modal
      isCentered
      size="2xl"
      isOpen={disclosure.isOpen}
      closeOnOverlayClick={false}
      onClose={onModalClose}
    >
      <ModalOverlay />
      <ModalContent p="6" borderRadius="3xl" userSelect="none">
        <ModalHeader p="0" bg="white" borderRadius="24px 24px 0 0">
          Your free trial has ended.
        </ModalHeader>
        <ModalCloseButton color="white" zIndex="2" />
        <ModalBody flexDirection="column" p="0">
          <Box
            height="100%"
            w="220px"
            top="0"
            right="0"
            position="absolute"
            bgSize="100%"
            bgRepeat="no-repeat"
            borderTopRightRadius="3xl"
            borderBottomRightRadius="3xl"
            bgImage={`${process.env.PUBLIC_URL}/assets/billing-trial-ended.png`}
          />
          {isOwner ? (
            <Flex py="4" direction="column" width="400px" gap="8">
              <Text color="gray.500" fontSize="s">
                Enjoyed your trial? Upgrade to continue getting all the features
                and support. Otherwise, we'll help you downgrade to our Free
                plan.
              </Text>
              <Flex direction="column" gap="2">
                <Flex
                  p="2"
                  gap="2"
                  cursor="pointer"
                  border="solid"
                  borderRadius="md"
                  direction="column"
                  borderWidth={isMonthlySelected ? "2px" : "1px"}
                  borderColor={isMonthlySelected ? "brand.500" : "gray.300"}
                  onClick={() => setIsMonthlySelected(true)}
                >
                  <Text fontWeight="bold">Monthly</Text>
                  <Text color="gray.400">$24/seat, billed every 30 days</Text>
                </Flex>
                <Flex
                  p="2"
                  mb="4"
                  gap="2"
                  cursor="pointer"
                  border="solid"
                  borderRadius="md"
                  direction="column"
                  borderWidth={isMonthlySelected ? "1px" : "2px"}
                  borderColor={isMonthlySelected ? "gray.300" : "brand.500"}
                  onClick={() => setIsMonthlySelected(false)}
                >
                  <Flex justifyContent="space-between">
                    <Text fontWeight="bold">Yearly</Text>
                    <Box borderRadius="2" bg="primary.500" color="white">
                      -20%
                    </Box>
                  </Flex>
                  <Text color="gray.400">$20/seat, billed every 365 days</Text>
                </Flex>
              </Flex>
              <Flex direction="column" gap="2" mt="4">
                <Button variant="light" onClick={() => onUpgradeClick(false)}>
                  Downgrade to our free plan!
                </Button>
                <Button onClick={() => onUpgradeClick(true)}>
                  Continue with Pro
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Box>
              <Text height="200px" py={5}>
                Please contact your workspace owner
              </Text>
              <Button onClick={onModalClose}>Ok</Button>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TrialExpiredModal;
