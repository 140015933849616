import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useAnalytics } from "shared/providers/AnalyticsContext";
import { PostHogEvents } from "shared/models/enums";

const ProFeatureModal = ({ disclosure, onUpgrade, isOwner }) => {
  const [isMonthlySelected, setIsMonthlySelected] = useState(false);
  const { trackEvent } = useAnalytics();

  const onUpgradeClick = () => {
    trackEvent(PostHogEvents.USER_UPGRADED_FROM_PRO_FEATURE_POPUP, {});
    onUpgrade(true);
  };

  const onModalClose = () => {
    trackEvent(PostHogEvents.USER_CLOSED_PRO_FEATURE_POPUP, {});
    disclosure.onClose();
  };

  return (
    <Modal
      isCentered
      size="2xl"
      isOpen={disclosure.isOpen}
      closeOnOverlayClick={false}
      onClose={onModalClose}
    >
      <ModalOverlay />
      <ModalContent p="6" borderRadius="3xl" userSelect="none">
        <ModalHeader p="0" bg="white" borderRadius="24px 24px 0 0" ml="220px">
          That’s a Pro feature!
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody flexDirection="column" p="0">
          <Box
            height="100%"
            w="220px"
            top="0"
            left="0"
            position="absolute"
            bgSize="100%"
            bgRepeat="no-repeat"
            borderTopLeftRadius="3xl"
            borderBottomLeftRadius="3xl"
            bgImage={`${process.env.PUBLIC_URL}/assets/billing-trial-ended.png`}
          />
          {isOwner ? (
            <Flex py="4" direction="column" width="400px" gap="8" ml="220px">
              <Text color="gray.500" fontSize="s">
                You’re at the limit of resources in your workspace. Stop the
                head-scratching moments and elevate your system design to the
                next level with our Pro plan.
              </Text>
              <Flex direction="column" gap="2">
                <Flex
                  p="2"
                  gap="2"
                  cursor="pointer"
                  border="solid"
                  borderRadius="md"
                  direction="column"
                  borderWidth={isMonthlySelected ? "2px" : "1px"}
                  borderColor={isMonthlySelected ? "brand.500" : "gray.300"}
                  onClick={() => setIsMonthlySelected(true)}
                >
                  <Text fontWeight="bold">Monthly</Text>
                  <Text color="gray.400">$24/seat, billed every 30 days</Text>
                </Flex>
                <Flex
                  p="2"
                  mb="4"
                  gap="2"
                  cursor="pointer"
                  border="solid"
                  borderRadius="md"
                  direction="column"
                  borderWidth={isMonthlySelected ? "1px" : "2px"}
                  borderColor={isMonthlySelected ? "gray.300" : "brand.500"}
                  onClick={() => setIsMonthlySelected(false)}
                >
                  <Flex justifyContent="space-between">
                    <Text fontWeight="bold">Yearly</Text>
                    <Box borderRadius="2" bg="primary.500" color="white">
                      -20%
                    </Box>
                  </Flex>
                  <Text color="gray.400">$20/seat, billed every 365 days</Text>
                </Flex>
              </Flex>
              <Flex direction="column" gap="2" mt="4">
                <Button
                  as={Link}
                  variant="light"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.multiplayer.app/pricing"
                  _hover={{ textDecoration: "none" }}
                >
                  See all benefits
                </Button>
                <Button onClick={onUpgradeClick}>Upgrade</Button>
              </Flex>
            </Flex>
          ) : (
            <Box ml="220px">
              <Text height="200px" py={5}>
                Please contact your workspace owner
              </Text>
              <Button onClick={onModalClose}>Ok</Button>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProFeatureModal;
