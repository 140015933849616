import { TracerBrowserSDK } from './otel'
import { RecorderBrowserSDK } from './rrweb'
import { getFormattedDate, getNavigatorInfo } from './helpers'
import { SessionWidget } from './sessionWidget'
import {
  SessionDebuggerConfigs,
  SessionDebuggerOptions,
  SessionState,
} from './types'
import './index.scss'
import {
  LOCAL_STORAGE_DEBUG_SESSION_ID_PROP_NAME,
  LOCAL_STORAGE_DEBUG_SESSION_SHORT_ID_PROP_NAME,
  LOCAL_STORAGE_DEBUG_SESSION_STATE_PROP_NAME,
  OTEL_MP_DOC_TRACE_RATIO,
  OTEL_MP_SAMPLE_TRACE_RATIO,
} from './constants'

export class MultiplayerSessionDebugger {
  private _tracer = new TracerBrowserSDK()
  private _recorder = new RecorderBrowserSDK()
  private _sessionWidget = new SessionWidget()
  private _configs: SessionDebuggerConfigs

  // Session ID and state are stored in localStorage
  private _sessionId: string | null = localStorage?.getItem(
    LOCAL_STORAGE_DEBUG_SESSION_ID_PROP_NAME,
  )
  private _shortSessionId: string | null = localStorage?.getItem(
    LOCAL_STORAGE_DEBUG_SESSION_SHORT_ID_PROP_NAME,
  )
  private _sessionState: SessionState = localStorage?.getItem(
    LOCAL_STORAGE_DEBUG_SESSION_STATE_PROP_NAME,
  ) as SessionState

  /**
   * Public getter and setter for session state
   */
  public get sessionState(): SessionState {
    return this._sessionState
  }

  public set sessionState(state: SessionState) {
    this._sessionState = state
    localStorage?.setItem(LOCAL_STORAGE_DEBUG_SESSION_STATE_PROP_NAME, state)
  }

  /**
   * Error message getter and setter to reflect on the session widget
   */
  public get error(): string {
    return this._sessionWidget.error
  }

  public set error(v: string) {
    this._sessionWidget.error = v
  }

  /**
   * Returns the HTML button element for the session widget's recorder button.
   *
   * This element is used to control the start/stop recording functionality in the session widget UI.
   *
   * @returns {HTMLButtonElement} The recorder button element from the session widget.
  */
  public get sessionWidgetButtonElement(): HTMLButtonElement {
    return this._sessionWidget.recorderButton
  }
  /**
   * Initialize debugger with default or custom configurations
   */
  constructor() {
    this._configs = {
      apiKey: '',
      version: '',
      application: '',
      environment: '',
      ignoreUrls: [],
      showWidget: true,
      recordButtonPlacement: 'bottom-right',
      exporterApiBaseUrl: 'https://api.multiplayer.app',
      canvasEnabled: false,
      docTraceRatio: OTEL_MP_DOC_TRACE_RATIO,
      sampleTraceRatio: OTEL_MP_SAMPLE_TRACE_RATIO,
      propagateTraceHeaderCorsUrls: [],
      schemifyDocSpanPayload: true,
      maskDebSpanPayload: true,
    }
  }

  /**
   * Initialize the session debugger
   * @param configs - custom configurations for session debugger
   */
  public init(configs: SessionDebuggerOptions): void {
    this._configs = { ...this._configs, ...configs }
    this._checkOperation('init')

    this._tracer.init(this._configs)
    this._recorder.init(this._configs)

    if (this._configs.showWidget) {
      this._sessionWidget.init(this._configs)
    }

    if (this._sessionId) {
      if (this._sessionState === SessionState.started) {
        this._start()
      }
    }

    this._registerWidgetEvents()
  }

  /**
   * Start a new session
   */
  public startSession(): void {
    this._checkOperation('start')
    this._createSessionAndStart()
  }

  /**
   * End the current session with an optional comment
   * @param comment - user-provided comment to include in session metadata
   */
  public endSession(comment?: string): void {
    this._checkOperation('stop')
    this._stop()
    this._makeRequest(`/debug-sessions/${this._sessionId}/stop`, 'PATCH', {
      userMetadata: { ...(comment ? { comment } : {}) },
      stoppedAt: this._recorder.stoppedAt,
    })
      .then(() => {
        // Handle session stop success
        this._setSession('', '')
      })
      .catch((error) => {
        this.error = error.message
      })
  }

  /**
   * Cancel the current session
   */
  public cancelSession(): void {
    this._checkOperation('cancel')
    this._stop()
    this._makeRequest(`/debug-sessions/${this._sessionId}/cancel`, 'DELETE')
      .then(() => {
        // Handle session cancel success
        this._setSession('', '')
      })
      .catch((error) => {
        this.error = error.message
      })
  }

  /**
   * Pause the current session
   */
  public pauseSession(): void {
    this._checkOperation('pause')
    this._pause()
  }
  /**
   * Pause the current session
   */
  public previewSession(): void {
    this._checkOperation('preview')
    this._recorder.preview(this._configs.canvasEnabled)
  }

  /**
   * Register session widget event listeners for controlling session actions
   */
  private _registerWidgetEvents(): void {
    this._sessionWidget.on('toggle', (state: boolean, comment?: string) => {
      this.error = ''
      state ? this.startSession() : this.endSession(comment?.trim())
    })

    this._sessionWidget.on('pause', () => {
      this.error = ''
      this.pauseSession()
    })

    this._sessionWidget.on('cancel', () => {
      this.error = ''
      this.cancelSession()
    })

    this._sessionWidget.on('preview', () => {
      this.previewSession()
    })
  }

  /**
   * Create a new session and start it
   */
  private async _createSessionAndStart(): Promise<void> {
    const clientMetadata = getNavigatorInfo()
    const metadata = window['mpSessionDebuggerMetadata'] || {}

    try {
      const session = await this._makeRequest('/debug-sessions/start', 'POST', {
        metadata,
        clientMetadata,
        name: metadata.userName
          ? `${metadata.userName}'s session on ${getFormattedDate(Date.now(), { month: 'short', day: 'numeric' })}`
          : `Session on ${getFormattedDate(Date.now())}`,
      })
      this._setSession(session._id, session.shortId)
      this._start()
    } catch (error: any) {
      this.error = error.message
    }
  }

  /**
   * Start tracing and recording for the session
   */
  private _start(): void {
    this._tracer.start(this._shortSessionId)
    this._recorder.start(this._sessionId)
    this._sessionWidget.isPaused = false
    this._sessionWidget.isStarted = true
    this.sessionState = SessionState.started
  }

  /**
   * Stop tracing and recording for the session
   */
  private _stop(): void {
    this._tracer.stop()
    this._recorder.stop()
    this._sessionWidget.isPaused = false
    this._sessionWidget.isStarted = false
    this.sessionState = SessionState.stopped
    this._recorder.clearStoredEvents()
  }

  /**
   * Pause the session tracing and recording
   */
  private _pause(): void {
    this._tracer.stop()
    this._recorder.stop()
    this._sessionWidget.isPaused = true
    this._sessionWidget.isStarted = false
    this.sessionState = SessionState.paused
  }


  /**
   * Set the session ID in localStorage
   * @param sessionId - the session ID to set or clear
   * @param shortSessionId - the short session ID to set or clear
   */
  private _setSession(
    sessionId: string,
    shortSessionId: string,
  ): void {
    this._sessionId = sessionId
    this._shortSessionId = shortSessionId
    if (this._sessionId) {
      localStorage?.setItem(
        LOCAL_STORAGE_DEBUG_SESSION_ID_PROP_NAME,
        this._sessionId,
      )
      localStorage?.setItem(
        LOCAL_STORAGE_DEBUG_SESSION_SHORT_ID_PROP_NAME,
        this._shortSessionId,
      )
    } else {
      localStorage?.removeItem(LOCAL_STORAGE_DEBUG_SESSION_ID_PROP_NAME)
      localStorage?.removeItem(LOCAL_STORAGE_DEBUG_SESSION_SHORT_ID_PROP_NAME)
      localStorage?.removeItem(LOCAL_STORAGE_DEBUG_SESSION_STATE_PROP_NAME)
    }
  }

  /**
   * Make a request to the session debugger API
   * @param url - API endpoint URL
   * @param method - HTTP method (GET, POST, PATCH, etc.)
   * @param body - request payload
   */
  private async _makeRequest(
    url: string,
    method: string,
    body?: any,
  ): Promise<any> {
    if (!this._configs.apiKey) {
      throw new Error('Configurations not set')
    }

    try {
      const response = await fetch(
        `${this._configs.exporterApiBaseUrl}/v0/radar${url}`,
        {
          method,
          body: body ? JSON.stringify(body) : null,
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': this._configs.apiKey,
          },
        },
      )

      if (!response.ok) {
        throw new Error('Network response was not ok: ' + response.statusText)
      }

      if (response.status === 204) {
        // No content
        return null
      }

      return await response.json()
    } catch (error: any) {
      this._sessionWidget.onRequestError()
      throw new Error('Error making request: ' + error.message)
    }
  }

  /**
   * Check the operation validity based on the session state and action
   * @param action - action being checked ('init', 'start', 'stop', 'cancel', 'pause')
   */
  private _checkOperation(
    action: 'init' | 'start' | 'stop' | 'cancel' | 'pause' | 'preview',
  ): void {
    if (!this._configs.apiKey) {
      throw new Error(
        'Configuration not initialized. Call init() before performing any actions.',
      )
    }

    switch (action) {
      case 'start':
        if (this._sessionState === SessionState.started) {
          throw new Error('Session is already started.')
        }
        break
      case 'stop':
        if (this._sessionState !== SessionState.paused) {
          throw new Error('Cannot stop. Session is not currently started.')
        }
        break
      case 'cancel':
        if (this._sessionState === SessionState.stopped) {
          throw new Error('Cannot cancel. Session has already been stopped.')
        }
        break
      case 'pause':
        if (this._sessionState !== SessionState.started) {
          throw new Error('Cannot pause. Session is not running.')
        }
        break
      case 'preview':
        if (this._sessionState !== SessionState.paused) {
          throw new Error('Cannot preview. Session is not paused.')
        }
        break
    }
  }
}