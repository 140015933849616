import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDisclosure } from "@chakra-ui/react";
import {
  ProFeatureModal,
  TrialExpiredModal,
} from "shared/components/BillingModals";
import * as WorkspaceService from "shared/services/workspace.service";

const BillingContext = createContext<{
  showProFeaturePopup: () => void;
  setIsCurrentUserTheOwner: React.Dispatch<React.SetStateAction<boolean>>;
  setAccountInfo: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  currentSubscription: any;
  getCurrentSubscription: (workspaceId: string) => Promise<void>;
}>(null);

export const BillingProvider = ({ children }) => {
  const proFeatureDisclosure = useDisclosure();
  const trialEndedDisclosure = useDisclosure();
  const [isCurrentUserTheOwner, setIsCurrentUserTheOwner] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState<any>({});
  const [accountInfo, setAccountInfo] = useState({
    accountId: null,
    workspaceId: null,
  });

  const trialModalKey = useMemo(
    () =>
      accountInfo?.accountId && accountInfo?.workspaceId
        ? accountInfo?.accountId +
          accountInfo?.workspaceId +
          "trialModalDismissed"
        : null,
    [accountInfo]
  );

  const trialModalKeyRef = useRef(trialModalKey);

  useEffect(() => {
    trialModalKeyRef.current = trialModalKey;
  }, [trialModalKey]);

  const onPlanUpgrade = async (upgradeToPro?: boolean) => {
    if (!accountInfo?.accountId) {
      console.warn("Account ID not found");
      return;
    }

    try {
      const res = await WorkspaceService.getAccountCustomerPortalUrl(
        accountInfo.accountId
      );
      if (res?.url) {
        window.open(res.url, "_blank");
      } else {
        console.warn("Customer portal URL not available.");
      }
    } catch {
      console.error("Failed to fetch customer portal URL");
    }
  };

  const getCurrentSubscription = useCallback(async (workspaceId) => {
    try {
      trialEndedDisclosure.onClose();
      const res = await WorkspaceService.getWorkspaceBilling(workspaceId);
      setCurrentSubscription(res);
      checkCurrentSubscription(res);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const checkCurrentSubscription = useCallback(
    (subscription) => {
      if (!trialModalKeyRef.current) {
        setTimeout(() => {
          checkCurrentSubscription(subscription);
        }, 300);
        return;
      }

      if (
        trialModalKeyRef.current &&
        localStorage.getItem(trialModalKeyRef.current)
      ) {
        trialEndedDisclosure.onClose();
        return;
      }

      const paidAtLeastOneTime = localStorage.getItem("paidAtLeastOneTime");
      if (
        paidAtLeastOneTime !== "true" &&
        isDatePassed(subscription.trialEndsAt)
      ) {
        trialEndedDisclosure.onOpen();
      }
    },
    [trialModalKeyRef]
  );

  const isDatePassed = (date: string) => {
    return Date.now() > new Date(date).getTime();
  };

  const showProFeaturePopup = () => {
    proFeatureDisclosure.onOpen();
  };

  return (
    <BillingContext.Provider
      value={{
        showProFeaturePopup,
        setIsCurrentUserTheOwner,
        setAccountInfo,
        currentSubscription,
        getCurrentSubscription,
      }}
    >
      <ProFeatureModal
        disclosure={proFeatureDisclosure}
        isOwner={isCurrentUserTheOwner}
        onUpgrade={onPlanUpgrade}
      />
      <TrialExpiredModal
        disclosure={trialEndedDisclosure}
        isOwner={isCurrentUserTheOwner}
        onUpgrade={onPlanUpgrade}
        trialModalKey={trialModalKey}
      />
      {children}
    </BillingContext.Provider>
  );
};

export function useBilling() {
  const context = useContext(BillingContext);
  if (context === null) {
    throw new Error("useBillingContext must be used within BillingProvider");
  }
  return context;
}
