import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSocket } from "shared/providers/SocketContext";
import { useAuth } from "shared/providers/AuthContext";
import { useBilling } from "shared/providers/BillingContext";
import {
  apiInstance,
  gitInstance,
  versionInstance,
  radarInstance,
} from "shared/api";

const ErrorHandler = () => {
  const { clearAuthData } = useAuth();
  const { showProFeaturePopup } = useBilling();
  const navigate = useNavigate();

  const { subscribe } = useSocket();

  useEffect(() => {
    const handleAuthError = (error) => {
      if (error && error.code === 401 && window.location.pathname !== "/auth") {
        localStorage.setItem("redirectTo", window.location.pathname);
        navigate("/auth");
        clearAuthData();
      }
    };

    const handlePlanLimitationError = (error) => {
      if (error && error.statusCode === 402) {
        showProFeaturePopup();
      }
    };

    const errorHandler = (error) => {
      handleAuthError(error);
      handlePlanLimitationError(error);
      return Promise.reject(error);
    };

    const apiInterceptors = apiInstance.interceptors.response.use(
      undefined,
      errorHandler
    );
    const versionInterceptors = versionInstance.interceptors.response.use(
      undefined,
      errorHandler
    );
    const gitInterceptors = gitInstance.interceptors.response.use(
      undefined,
      errorHandler
    );
    const radarInterceptors = radarInstance.interceptors.response.use(
      undefined,
      errorHandler
    );

    return () => {
      apiInstance.interceptors.response.eject(apiInterceptors);
      versionInstance.interceptors.response.eject(versionInterceptors);
      gitInstance.interceptors.response.eject(gitInterceptors);
      radarInstance.interceptors.response.eject(radarInterceptors);
    };
  }, [clearAuthData, subscribe, navigate]);

  return null;
};

export default ErrorHandler;
